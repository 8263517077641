<template>
  <div class="page inPage indexBarPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">IndexBar</div>
    </div>
    <div class="pageBody">
      <lee-index-bar ref="indexBar">
        <lee-index-anchor index="A"/>
        <lee-cell title="阿冗"/>
        <lee-cell title="AG超玩会梦泪"/>
        <lee-cell title="爱吃巧乐兹"/>
        <lee-cell title="艾福杰尼"/>
        <lee-cell title="艾瑞欧ERIOE"/>
        <lee-cell title="阿云嘎"/>
        <lee-cell title="安九"/>
        <lee-index-anchor index="B"/>
        <lee-cell title="宝藏男孩"/>
        <lee-cell title="白翼实"/>
        <lee-cell title="白敬亭"/>
        <lee-cell title="白举纲"/>
        <lee-cell title="不是花火呀"/>
        <lee-index-anchor index="C"/>
        <lee-cell title="陈柯宇"/>
        <lee-cell title="陈柯右"/>
        <lee-cell title="陈坤"/>
        <lee-cell title="陈乐一"/>
        <lee-cell title="陈粒"/>
        <lee-cell title="陈琳"/>
        <lee-cell title="陈明"/>
        <lee-cell title="陈佩廷"/>
        <lee-cell title="陈其钢"/>
        <lee-cell title="陈思思"/>
        <lee-cell title="陈翔"/>
        <lee-index-anchor index="D"/>
        <lee-cell title="戴燕妮"/>
        <lee-cell title="大笨"/>
        <lee-cell title="丁芙妮"/>
        <lee-cell title="丁满"/>
        <lee-cell title="Dirbig大民民"/>
        <lee-cell title="董唧唧"/>
        <lee-cell title="东跑西颠"/>
        <lee-cell title="东说西唱"/>
        <lee-cell title="董文华"/>
        <lee-cell title="窦靖童"/>
        <lee-cell title="杜以东"/>
        <lee-cell title="戴天抒"/>
        <lee-index-anchor index="E"/>
        <lee-cell title="EO羿欧"/>
        <lee-cell title="Emo头孢"/>
        <lee-cell title="恶梦Y"/>
        <lee-cell title="贰佰"/>
        <lee-cell title="ESTA沈瞳伽"/>
        <lee-cell title="二毛拖拉机"/>
        <lee-cell title="二手玫瑰乐队"/>
        <lee-index-anchor index="F"/>
        <lee-cell title="范特西"/>
        <lee-cell title="房东的猫"/>
        <lee-cell title="峰锅"/>
        <lee-cell title="凤凰传奇"/>
        <lee-cell title="傅如乔"/>
        <lee-cell title="付雪"/>
        <lee-cell title="傅菁"/>
        <lee-cell title="飞行少年"/>
        <lee-index-anchor index="G"/>
        <lee-cell title="Gm仙"/>
        <lee-cell title="GAS闻西"/>
        <lee-cell title="狗跳跳"/>
        <lee-cell title="国风集"/>
        <lee-cell title="怪獣KAIJU"/>
        <lee-cell title="耿斯汉"/>
        <lee-cell title="龚琳娜"/>
        <lee-index-anchor index="H"/>
        <lee-cell title="和平精英"/>
        <lee-cell title="呼延天"/>
        <lee-cell title="胡彦斌"/>
        <lee-cell title="胡歌"/>
        <lee-cell title="HughLion_小狮子"/>
        <lee-cell title="黄毓"/>
        <lee-cell title="黄礼格"/>
        <lee-cell title="胡66"/>
        <lee-cell title="猴儿财神"/>
        <lee-cell title="贺国丰"/>
        <lee-cell title="黄工"/>
        <lee-cell title="韩磊"/>
        <lee-cell title="货拉拉师傅"/>
        <lee-index-anchor index="J"/>
        <lee-cell title="绝境拒分"/>
        <lee-cell title="鞠婧祎"/>
        <lee-cell title="JUNGO"/>
        <lee-cell title="JDS"/>
        <lee-cell title="疾风与她"/>
        <lee-cell title="降央卓玛"/>
        <lee-cell title="姜云升"/>
        <lee-cell title="简弘亦"/>
        <lee-cell title="坚强的小黄瓜"/>
        <lee-cell title="焦迈奇"/>
        <lee-index-anchor index="K"/>
        <lee-cell title="空匪"/>
        <lee-cell title="肯迪仔"/>
      </lee-index-bar>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'
import {ref,onMounted} from 'vue'

const router = useRouter()
const back = () => {
    router.push('/')
}
const indexBar=ref(null)
onMounted(()=>{
    //indexBar.value.scrollTo('D')
})


</script>
<style>
.indexBarPage .position{
  position: relative;
  width: 100%;
}
</style>
